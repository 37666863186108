$(document).on('click', '.toggle-menu', function(){
    let menu = $('.mobile-menu')
    let body = $('body')

    menu.stop().fadeToggle(300)
    body.toggleClass('menu-opened')
})

function headerScroll() {
    const scrolled = Math.round($(window).scrollTop())
    const btn = $('.to-up')

    if(scrolled) {
        btn.addClass('show')
    }else{
        btn.removeClass('show')
    }

}

headerScroll()

$(window).scroll(headerScroll)

$(document).ready(function () {
    $('.site-menu').addClass('inited')
})

$(document).on('click', '.toggle-menu', function(){
    let menu = $('.site-menu')

    menu.toggleClass('show')
})

$(document).on('click', '.close-menu', function(){
    let menu = $('.site-menu')

    menu.removeClass('show')
})

$(document).mouseup( function(e){
    let menu_group = $('.site-menu, .toggle-menu')

    if ( !menu_group.is(e.target) && menu_group.has(e.target).length === 0 ) {
        menu_group.removeClass('show')
    }
})