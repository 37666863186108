function update_height() {
    let items = $('.grid .card .content')
    let heighs = []

    items.css('height', 'auto')

    items.each(function(){
        let item = $(this)
        heighs.push(item[0].clientHeight)
    })

    let max = Math.max(...heighs)
    items.css('height', max)
}

update_height()

$(window).on('resize', update_height)

$(document).ready(function () {
    update_height()
})