$(document).on('click', '.close-popup', function() {
    let elem = $(this)
    let wrapper = elem.parents('.popup')

    wrapper.stop().fadeOut(300)
})

$(document).on('click', '.open-popup', function() {
    let elem = $(this)
    let popup = $('#' + elem.data('popup'))

    popup.stop().fadeIn(300)
})