const sliders = {
	'.first-slide': {
		dots: true,
		// lazyLoad: 'ondemand',
		autoplay: true,
  		autoplaySpeed: 3000,
		prevArrow: '<button class="arrow prev"><span class="icon icon-left"></span></button>',
		nextArrow: '<button class="arrow next"><span class="icon icon-right"></span></button>',
		responsive: [
			{
				breakpoint: 767.99,
				settings: {
					arrows: false,
					dots: true
				}
			}
		]
	},
}

$.each(sliders, (name, options) => {
	const slider = $(name)

	if (slider.length) {
		slider.slick(options)
	}
})