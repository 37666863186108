$(document).on('click', '.scroll-down', function(){
    let elem = $(this)
    let section = elem.parents('section')
    let next = section.next()

    jQuery("html:not(:animated),body:not(:animated)").animate({
      scrollTop: next.offset().top
    }, 600)
})

$(document).on('click', '.to-up', function(){
  jQuery("html:not(:animated),body:not(:animated)").animate({
    scrollTop: 0
  }, 600)
})